
































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import ImageUploadGallery, { UploadImageData } from '@/components/ImageUploadGallery.vue';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';

@Component({
  components: { ImageUploadGallery, TextHeader },
})
export default class MultipleImageUpload extends Vue {

  @VModel()
  private galleryImages!: UploadImageData[];

  @Prop({ default: true })
  private showHeading!: boolean;

  @Prop({ default: [] })
  private errors!: string[];

  @Prop({ default: 5 })
  private readonly maxImageSizeInMB!: number;

  @Emit()
  private uploadImage(imageFile: File) {
    // empty, emits event
  }

  @Emit()
  private deleteImage(uuid: string) {
    // empty, emits event
  }

  private Header = Header;

  private DEFAULT_BORDER_COLOR: string = 'gray-200';
  private DRAG_HIGHLIGHT_BORDER_COLOR: string = 'accent-blue';

  private galleryUploadBorderColor: string = this.DEFAULT_BORDER_COLOR;
  private isFileTypeInvalid: boolean = false;
  private isImageSizeTooHigh: boolean = false;
  private filesInputValue = null;

  private get errorsToDisplay() {

    const result = [...this.errors];

    if (this.isFileTypeInvalid) {
      result.push('INVALID_IMAGE_TYPE');
    }

    if (this.isImageSizeTooHigh) {
      result.push('IMAGE_FILE_SIZE_TOO_HIGH');
    }

    return result;
  }

  private isFileValid(file: File | null) {

    if (!file) return false;

    this.isFileTypeInvalid = !(file.type === 'image/png' || file.type === 'image/jpeg');

    const fileSizeInMB: number = file.size / 1024 / 1024;
    this.isImageSizeTooHigh = fileSizeInMB > this.maxImageSizeInMB;

    return !this.isFileTypeInvalid && !this.isImageSizeTooHigh;
  }
  private uploadGalleryImage(file: File) {
    this.uploadImage(file);
  }

  private async onGalleryFileSelectionChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target && target.files && target.files.length > 0) {
      const filesToAdd: File[] = Array.from(target.files);
      await this.addGalleryImagesFromSelection(filesToAdd);
    }
  }

  private async onDropLogoGalleryImageFiles(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const filesToAdd: File[] = Array.from(event.dataTransfer.files);
      await this.addGalleryImagesFromSelection(filesToAdd);
    }
    event.preventDefault();
    this.galleryUploadBorderColor = this.DEFAULT_BORDER_COLOR;
  }

  private async addGalleryImagesFromSelection(files: File[]) {
    // noinspection ES6MissingAwait
    await files.forEach(async (f) => {
      if (this.galleryImages.length < 10 && this.isFileValid(f)) {
        // this.selectedGalleryImageFiles.push(f);
        this.uploadGalleryImage(f);
      } else if (this.galleryImages.length >= 10) {
        this.errors.push('TOO_MANY_GALLERY_IMAGES');
      }
    });
  }

  private removeGalleryImage(imageId: string) {
    this.deleteImage(imageId);
  }

  private onDragEnterGalleryArea() {
    this.galleryUploadBorderColor = this.DRAG_HIGHLIGHT_BORDER_COLOR;
  }

  private onDragExitGalleryArea() {
    this.galleryUploadBorderColor = this.DEFAULT_BORDER_COLOR;
  }

  private inputOnClick() {
    // Reset to null for change event to be emitted always.
    // Even if the same image is selected again
    this.filesInputValue = null;
  }
}
