




















import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { NewsValidationError } from '@/views/news/NewsEditor.vue';
import { UploadImageData as GalleryImage } from '@/components/ImageUploadGallery.vue';
import MultipleImageUpload from '@/components/MultipleImageUpload.vue';
import { FileUpload, NewsBlock } from '@/apis/newsapi';
import Services from '@/assets/services/Services';
import BaseNewsBlock from '@/components/news/BaseNewsBlock.vue';

@Component({
  components: { MultipleImageUpload, BaseNewsBlock },
})
export default class GalleryBlock extends Vue {

  @VModel({ required: true })
  private data!: NewsBlock;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private readonly deleteButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: ([]) })
  private errors!: NewsValidationError[];

  private get errorsToDisplay(): string[] {
    const matchingErrorCodes: string[] = this.errors.filter((e) => e.location === 'BLOCK' && e.blockPosition === this.data.position).map((e) => e.code);
    const errorMessages: string[] = [];

    matchingErrorCodes.forEach((code) => {
      if (code === 'GALLERY_EMPTY') errorMessages.push('Es muss mindestens ein Bild vorhanden sein');
    });

    return errorMessages;
  }

  private get galleryImageData(): GalleryImage[] {

    if (!this.data || !this.data.images) {
      return [];
    }

    return this.data.images.map((i) => ({
      uuid: i.uuid as string,
      path: i.isTemporaryUpload ? `${process.env.VUE_APP_NEWS_SERVICE_IMAGE_URL}temporary/${i.uuid}` : `${process.env.VUE_APP_NEWS_SERVICE_IMAGE_URL}${i.uuid}`,
      filename: `${i.filename}.${i.filetype}`,
    }));
  }

  private async uploadGalleryImage(file: File) {
    const response = await Services.news.uploadImageTemporary(file);
    const image: FileUpload = response.data;
    image.isTemporaryUpload = true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.data.images.push(image);
  }

  private async removeGalleryImage(imageId: string) {
    if (this.data.images) this.data.images = this.data.images.filter((img) => img.uuid !== imageId);
  }

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }
}
