













































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';

export interface UploadImageData {
  path: string;
  filename: string;
}

@Component({
  components: { LoadingAnimation, TextHeader },
})
export default class SingleImageUpload extends Vue {

  @VModel({ default: () => ({ path: '', filename: '' }) })
  private uploadedLogoData!: UploadImageData;

  @Prop({ default: true })
  private showHeading!: boolean;

  @Prop({ default: 'Logo' })
  private readonly heading!: string;

  @Prop({ type: Array, default: () => ([]) })
  private readonly errors!: string[];

  @Prop({ default: 'logo' })
  private readonly fileInputId!: string;

  @Prop({ default: ([]) })
  private errorMessages!: string[];

  @Prop({ default: false })
  private uploadInProgress!: boolean;

  @Emit()
  private uploadImage(imageFile: File) {
    // empty, emits event
  }

  @Emit()
  private deleteImage() {
    // empty, emits event
  }

  private Header = Header;

  private DEFAULT_BORDER_COLOR: string = 'gray-200';
  private DRAG_HIGHLIGHT_BORDER_COLOR: string = 'accent-blue';

  private logoUploadBorderColor: string = this.DEFAULT_BORDER_COLOR;
  private logoImageFile: File | null = null;
  private logoSuccessFullyUploaded: boolean = false;
  private imageSizeTooHigh = false;

  private mounted() {
    this.logoSuccessFullyUploaded = this.uploadedLogoData.path !== '';
  }

  private get errorsToDisplay() {
    if (this.imageSizeTooHigh) {
      return [...this.errors, 'IMAGE_FILE_SIZE_TOO_HIGH'];
    }
    return this.errors;
  }

  private onDragEnterLogoArea() {
    this.logoUploadBorderColor = this.DRAG_HIGHLIGHT_BORDER_COLOR;
  }

  private onDragExitLogoArea() {
    this.logoUploadBorderColor = this.DEFAULT_BORDER_COLOR;
  }

  private isFileValid(file: File | null) {

    const valid = file && (file.type.startsWith('video'));
    if (!valid) this.errors.push('LOGO_INVALID_IMAGE_TYPE');

    return valid;
  }

  private uploadLogo(file: File) {
    this.uploadImage(file);
    this.logoSuccessFullyUploaded = true;
  }

  private async logoImageSelectionChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const file = target.files.item(0);
      if (this.isFileValid(file)) {
        this.logoImageFile = target.files.item(0);
        this.uploadLogo(this.logoImageFile as File);
      }
    }
    event.preventDefault();
    this.logoUploadBorderColor = this.DEFAULT_BORDER_COLOR;
  }

  private async onDropLogoImageFile(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file: File | null = event.dataTransfer.files.item(0);
      if (this.isFileValid(file)) {
        this.logoImageFile = file;
        this.uploadLogo(this.logoImageFile as File);
      }
    }
    event.preventDefault();
    this.logoUploadBorderColor = this.DEFAULT_BORDER_COLOR;
  }

  private removeSelectedLogoImage() {
    this.logoImageFile = null;
    this.logoSuccessFullyUploaded = false;
    this.deleteImage();
  }
}
