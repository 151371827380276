

































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import MarkdownEditor from '@/components/partials/MarkdownEditor.vue';
import { NewsValidationError } from '@/views/news/NewsEditor.vue';
import Services from '@/assets/services/Services';
import SurveyCheckBoxTable from '@/components/surveys/SurveyCheckBoxTable.vue';
import SingleImageUpload, { UploadImageData } from '@/components/SingleImageUpload.vue';
import { FileUpload, NewsBlock } from '@/apis/newsapi';
import BaseNewsBlock from '@/components/news/BaseNewsBlock.vue';

@Component({
  components: {
    MarkdownEditor, SurveyCheckBoxTable, SingleImageUpload, BaseNewsBlock,
  },
})
export default class TextWithImageBlock extends Vue {

  @VModel({ required: true })
  private data!: NewsBlock;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private readonly deleteButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: ([]) })
  private errors!: NewsValidationError[];

  private fileInputId = (Math.random() + 1).toString(36).substring(7);

  private get errorsToDisplay(): string[] {
    const matchingErrorCodes: string[] = this.errors.filter((e) => e.location === 'BLOCK' && e.blockPosition === this.data.position).map((e) => e.code);
    const errorMessages: string[] = [];

    matchingErrorCodes.forEach((code) => {
      if (code === 'TEXT_EMPTY') errorMessages.push('Der Text darf nicht leer sein');
      if (code === 'IMAGE_UNDEFINED') errorMessages.push('Es muss ein Bild vorhanden sein');
    });

    return errorMessages;
  }

  private mounted() {
    if (this.data.images && this.data.images.length <= 0) {
      this.data.images[0] = {
        uuid: undefined,
        filename: undefined,
        filetype: undefined,
        isTemporaryUpload: true,
      };
      this.uploadedImageExists = false;
    } else {
      this.uploadedImageExists = true;
    }
  }

  private uploadedImageExists: boolean = false;

  private get imagePath(): UploadImageData {

    const imageData: UploadImageData = {
      path: '',
      filename: '',
    };

    if (this.uploadedImageExists && this.data.images && this.data.images.length > 0) {
      imageData.filename = `${this.data.images[0].filename}.${this.data.images[0].filetype}`;

      if (this.data.images[0].isTemporaryUpload) {
        imageData.path = `${process.env.VUE_APP_NEWS_SERVICE_IMAGE_URL}temporary/${this.data.images[0].uuid}`;
      } else {
        imageData.path = `${process.env.VUE_APP_NEWS_SERVICE_IMAGE_URL}${this.data.images[0].uuid}`;
      }
    }

    return imageData;
  }

  private async uploadImage(file: File) {
    if (this.uploadedImageExists) {
      await this.deleteImage();
    }
    const response = await Services.news.uploadImageTemporary(file);
    if (this.data.images) {
      this.data.images[0].uuid = response.data.uuid;
      this.data.images[0].filename = response.data.filename;
      this.data.images[0].filetype = response.data.filetype;
      this.data.images[0].isTemporaryUpload = response.data.isTemporaryUpload;
    }

    this.uploadedImageExists = true;
  }

  private async deleteImage() {
    if (this.data.images) {
      this.data.images[0].uuid = undefined;
      this.data.images[0].filename = undefined;
      this.data.images[0].filetype = undefined;
      this.data.images[0].isTemporaryUpload = undefined;
      this.uploadedImageExists = false;
    }
  }

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }
}
