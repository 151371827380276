




























































































































































































































































































import { AxiosError, AxiosResponse } from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  AppTypeRestDto,
  AppTypeRestDtoAppTypeEnum,
  ProjectRestDto,
  ProjectUploadRestDto,
  PublishTypeEnum,
} from '@/apis/projectapi';
import { RejectionReason } from '@/apis/surveyapi';
import { CompanyInformation } from '@/apis/userapi';
import ButtonType from '@/assets/buttonTypes';
import Header from '@/assets/headers';
import MessageBoxType from '@/assets/messageBoxTypes';
import Role from '@/assets/roles';
import DateService from '@/assets/services/DateService';
import Services from '@/assets/services/Services';
import AppVersionType, { AppInput } from '@/assets/types/appversiontypes';
import AppPermission from '@/assets/types/permissions';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { UploadImageData as GalleryImage } from '@/components/ImageUploadGallery.vue';
import MultipleImageUpload from '@/components/MultipleImageUpload.vue';
import BackLinkWithArrow from '@/components/partials/BackLinkWithArrow.vue';
import LeafletMap from '@/components/partials/LeafletMap.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import MarkdownEditor from '@/components/partials/MarkdownEditor.vue';
import MessageBox from '@/components/partials/MessageBox.vue';
import Page from '@/components/partials/Page.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import AppVersionInput from '@/components/projects/AppVersionInput.vue';
import SingleImageUpload, { UploadImageData } from '@/components/SingleImageUpload.vue';
import SurveyRejectionCard from '@/components/table/SurveyRejectionCard.vue';
import Table from '@/components/table/Table.vue';

@Component({
  components: {
    MarkdownEditor,
    ConfirmDialog,
    SurveyRejectionCard,
    Table,
    TextInputArea,
    LoadingAnimation,
    MultipleImageUpload,
    SingleImageUpload,
    AppVersionInput,
    RoundedButtonFilled,
    RoundedButtonOutlined,
    TextInput,
    TextHeader,
    BackLinkWithArrow,
    MessageBox,
    LeafletMap,
    Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class ProjectEditing extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private Role = Role;
  private AppVersionType = AppVersionType;
  private AppPermission = AppPermission;
  private MessageBoxType = MessageBoxType;
  private PublishState = PublishTypeEnum;

  @Prop({ default: undefined })
  private readonly projectUuidProp!: string;

  private contactPageLink = `${process.env.VUE_APP_FRONTEND_URL}/contact`;
  private editExistingProject = !!this.projectUuidProp;
  private showSuccessMessage: boolean = false;
  private uploadSuccessful: boolean = false;
  private loading: boolean = false;
  private errors: string[] = [];
  private companyDataAvailable: boolean = true;

  private logoImageFile: File | null = null;
  private uploadedLogoData: ProjectUploadRestDto = {};
  private logoSuccessFullyUploaded: boolean = false;

  private showConfirmReviewDialog: boolean = false;
  private showRejectionDialog: boolean = false;
  private rejectionDialogErrorMessages: string[] = [];
  private rejectionReasonText: string = '';

  private galleryImages: ProjectUploadRestDto[] = [];

  private get galleryImageData(): GalleryImage[] {
    return this.galleryImages.map((i) => ({
      uuid: i.uuid as string,
      path: i.isTemporaryUpload ? `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}temporary/${i.id}` : `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}${i.id}`,
      filename: `${i.filename}.${i.filetype}`,
    }));
  }

  private appInputs: {android: AppInput, ios: AppInput, web: AppInput} = {
    android: this.emptyAppModel,
    ios: this.emptyAppModel,
    web: this.emptyAppModel,
  }

  private showIOSAppForm: boolean = false;
  private showAndroidAppForm: boolean = false;
  private showWebAppForm: boolean = false;

  private permissions: AppPermission[] = AppPermission.ALL_PERMISSIONS;

  // {Bluetooth: false, Camera: true, ...}
  private permissionInput = Object.fromEntries(this.permissions.map((permission) => [permission.name, false]));

  private project: ProjectRestDto = {
    id: undefined,
    developerId: '',
    name: '',
    website: '',
    nameSlug: undefined,
    shortDescription: '',
    description: '',
    clientId: '',
    clientSecret: '',
    usesBluetooth: this.permissionInput[AppPermission.BLUETOOTH.name],
    usesBodyData: this.permissionInput[AppPermission.BODY_DATA.name],
    usesCamera: this.permissionInput[AppPermission.CAMERA.name],
    usesCalendar: this.permissionInput[AppPermission.CALENDAR.name],
    usesContacts: this.permissionInput[AppPermission.CONTACTS.name],
    usesFilesystem: this.permissionInput[AppPermission.FILESYSTEM.name],
    usesGps: this.permissionInput[AppPermission.LOCATION.name],
    usesMicrophone: this.permissionInput[AppPermission.MICROPHONE.name],
    usesNotes: this.permissionInput[AppPermission.NOTES.name],
    usesVoiceRecognition: this.permissionInput[AppPermission.SPEECH_RECOGNITION.name],
    usesPhotos: this.permissionInput[AppPermission.PHOTOS.name],
    usesSingleSignOn: this.permissionInput[AppPermission.SINGLE_SIGN_ON.name],
    usesSurveys: this.permissionInput[AppPermission.SURVEYS.name],
    uploads: [],
    types: [],
    publishState: PublishTypeEnum.Editing,
    isPublished: false,
    rejections: [],
    federalStates: [],
    counties: [],
    markers: [],
  };

  private developerIdToNameMap: Map<string, string> = new Map<string, string>();

  private projectUsesSingleSignOn : boolean | undefined = false;
  private projectUsesSurveys : boolean | undefined = false;
  private initialLoading : boolean = true;
  private showUnexpectedErrorMessage = false;

  private dateTimeFormatter = DateService.dateTimeFormatter;

  private formatRejectionDate(date: string) {
    return this.dateTimeFormatter.format(new Date(Date.parse(date)));
  }

  private get currentRejectionReasonText(): string {
    if (this.editExistingProject && this.project && this.project.rejections && this.project.rejections.length > 0) {
      return this.rejectionsSortedByDate[0].text as string;
    }

    return '';
  }

  private get rejectionsSortedByDate(): RejectionReason[] {
    if (!this.project || !this.project.rejections) return [];

    return this.project.rejections.sort(
      (a: RejectionReason, b: RejectionReason) => Date.parse(b.date as string) - Date.parse(a.date as string),
    );
  }

  private async mounted(): Promise<void> {
    if (this.editExistingProject) {
      try {
        const response = await Services.projects.getProjectById(this.projectUuidProp);
        this.project = response.data;
        this.projectUsesSingleSignOn = response.data.usesSingleSignOn;
        this.projectUsesSurveys = response.data.usesSurveys;

        if (this.project.usesSingleSignOn) {
          this.updateClientInformationInView(this.project);
        } else {
          AppPermission.resetSingleSignOn();
        }

        if (this.project.usesSurveys) {
          this.updateSurveyKeyInformationInView(this.project);
        } else {
          AppPermission.resetSurveys();
        }

        if (this.project.uploads) {
          this.galleryImages = this.project.uploads?.filter((upload: ProjectUploadRestDto) => !upload.isAppLogo) as ProjectUploadRestDto[];
          this.uploadedLogoData = this.project.uploads?.find((upload: ProjectUploadRestDto) => upload.isAppLogo) as ProjectUploadRestDto;
        }

        if (this.project.types) {
          this.mapAppDtoToAppInputs(this.project.types);
        }

        this.mapProjectPermissionsToPermissionInputs();

        if (this.$store.state.role === Role.ADMIN) {
          await this.loadAllDeveloperNames();
        }

      } catch (error) {
        this.showUnexpectedErrorMessage = true;
      }
    } else {
      this.project.developerId = this.$store.state.userId;

      const companyInformationResponse = await Services.users.getUserCompanyInformationByUserId(this.project.developerId as string);
      const companyInformation: CompanyInformation = companyInformationResponse.data;

      this.companyDataAvailable = !!companyInformation
        && !!companyInformation.companyName
        && (companyInformation.companyName.trim() !== '')
        && !!companyInformation.companyDescription
        && (companyInformation.companyDescription.trim() !== '');

      this.permissions.map((p) => {
        if (p === AppPermission.SINGLE_SIGN_ON) {
          AppPermission.resetSingleSignOn();
        }
        return p;
      });
    }

    this.initialLoading = false;
  }

  private async loadAllDeveloperNames() {

    if (!this.project.rejections) return;

    const promises = [];
    const processedDeveloperIds: string[] = Array.from(this.developerIdToNameMap.keys());

    for (let i = 0; i < this.project.rejections.length; i += 1) {

      const developerId: string = this.project.rejections[i].reviewerUuid as string;

      if (developerId && !processedDeveloperIds.includes(developerId)) {
        promises.push(this.loadDeveloperName(developerId));
        processedDeveloperIds.push(developerId);
      }
    }

    await Promise.all(promises);
  }

  private async loadDeveloperName(developerId: string) {
    if (developerId && developerId !== '' && !this.developerIdToNameMap.has(developerId)) {
      const response = await Services.users.getUserByUserId(developerId);
      this.developerIdToNameMap.set(developerId, `${response.data.firstname} ${response.data.lastname}`);
    }
  }

  private get emptyAppModel(): AppInput {
    return {
      id: 0,
      osVersionId: 1,
      languages: [],
      size: '',
      weblink: '',
    };
  }

  private get logoImagePath(): UploadImageData {

    const imageData: UploadImageData = {
      path: '',
      filename: '',
    };

    if (this.uploadedLogoData && this.uploadedLogoData.id) {

      imageData.filename = `${this.uploadedLogoData.filename}.${this.uploadedLogoData.filetype}`;

      if (this.uploadedLogoData.isTemporaryUpload) {
        imageData.path = `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}temporary/${this.uploadedLogoData.id}`;
      } else {
        imageData.path = `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}${this.uploadedLogoData.id}`;
      }
    }

    return imageData;
  }

  private async uploadLogo(file: File) {
    if (this.uploadedLogoData && this.uploadedLogoData.id !== undefined) {
      await this.removeSelectedLogoImage();
    }
    const response = await Services.projects.uploadImageTemporary(true, file);
    this.uploadedLogoData = response.data;
    this.logoSuccessFullyUploaded = true;
  }

  private async uploadGalleryImage(file: File) {
    const response = await Services.projects.uploadImageTemporary(false, file);
    const image: ProjectUploadRestDto = response.data;
    image.isTemporaryUpload = true;
    this.galleryImages.push(image);
  }

  private async removeSelectedLogoImage() {
    this.logoImageFile = null;
    this.logoSuccessFullyUploaded = false;
    this.uploadedLogoData = {};
  }

  private async removeGalleryImage(imageId: string) {
    const imgToDelete: ProjectUploadRestDto | undefined = this.galleryImages.find((img) => img.uuid === imageId);

    if (imgToDelete) {
      this.galleryImages = this.galleryImages.filter((img) => img.uuid !== imageId);
    }
  }

  private deleteAndroidApp() {
    this.showAndroidAppForm = false;
    this.appInputs.android = this.emptyAppModel;
  }

  private deleteIOSApp() {
    this.showIOSAppForm = false;
    this.appInputs.ios = this.emptyAppModel;
  }

  private deleteWebApp() {
    this.showWebAppForm = false;
    this.appInputs.web = this.emptyAppModel;
  }

  private mapAppDtoToAppInputs(apps: AppTypeRestDto[]) {
    for (let i = 0; i < apps.length; i += 1) {
      const app: AppTypeRestDto = apps[i];
      const appInput: AppInput = this.appDtoToAppInput(app);

      if (app.appType === AppTypeRestDtoAppTypeEnum.Android) {
        this.appInputs.android = appInput;
        this.showAndroidAppForm = true;
      } else if (app.appType === AppTypeRestDtoAppTypeEnum.Ios) {
        this.appInputs.ios = appInput;
        this.showIOSAppForm = true;
      } else {
        this.appInputs.web = appInput;
        this.showWebAppForm = true;
      }
    }
  }

  private appDtoToAppInput(app: AppTypeRestDto): AppInput {

    let versionId: number = 0;

    if (app.appType === AppTypeRestDtoAppTypeEnum.Android) {
      versionId = app.minAndroidVersion?.id as number;
    } else if (app.appType === AppTypeRestDtoAppTypeEnum.Ios) {
      versionId = app.minIOSVersion?.id as number;
    }

    return {
      osVersionId: versionId,
      id: app.id as number,
      size: app.size ? String(app.size) : '',
      weblink: app.weblink as string,
      languages: (app.languages as string[]).filter((l) => l && l.length > 0),
    };
  }

  private mapAppInputsToDto(): AppTypeRestDto[] {

    const apps: AppTypeRestDto[] = [];
    if (this.showAndroidAppForm) {
      apps.push({
        id: this.appInputs.android.id,
        appId: this.project.id,
        appType: AppTypeRestDtoAppTypeEnum.Android,
        minAndroidVersion: {
          id: this.appInputs.android.osVersionId,
          name: '',
        },
        minIOSVersion: undefined,
        weblink: this.appInputs.android.weblink,
        languages: this.appInputs.android.languages,
        size: Number(this.appInputs.android.size.replace(',', '.')),
      });
    }

    if (this.showIOSAppForm) {
      apps.push({
        id: this.appInputs.ios.id,
        appId: this.project.id,
        appType: AppTypeRestDtoAppTypeEnum.Ios,
        minAndroidVersion: undefined,
        minIOSVersion: {
          id: this.appInputs.ios.osVersionId,
          name: '',
        },
        weblink: this.appInputs.ios.weblink,
        languages: this.appInputs.ios.languages,
        size: Number(this.appInputs.ios.size.replace(',', '.')),
      });
    }

    if (this.showWebAppForm) {
      apps.push({
        id: this.appInputs.web.id,
        appId: this.project.id,
        appType: AppTypeRestDtoAppTypeEnum.Webapp,
        minAndroidVersion: undefined,
        minIOSVersion: undefined,
        weblink: this.appInputs.web.weblink,
        languages: this.appInputs.web.languages,
      });
    }

    return apps;
  }

  private mapProjectPermissionsToPermissionInputs() {
    this.permissionInput[AppPermission.BLUETOOTH.name] = this.project.usesBluetooth as boolean;
    this.permissionInput[AppPermission.BODY_DATA.name] = this.project.usesBodyData as boolean;
    this.permissionInput[AppPermission.CAMERA.name] = this.project.usesCamera as boolean;
    this.permissionInput[AppPermission.CALENDAR.name] = this.project.usesCalendar as boolean;
    this.permissionInput[AppPermission.CONTACTS.name] = this.project.usesContacts as boolean;
    this.permissionInput[AppPermission.FILESYSTEM.name] = this.project.usesFilesystem as boolean;
    this.permissionInput[AppPermission.LOCATION.name] = this.project.usesGps as boolean;
    this.permissionInput[AppPermission.MICROPHONE.name] = this.project.usesMicrophone as boolean;
    this.permissionInput[AppPermission.NOTES.name] = this.project.usesNotes as boolean;
    this.permissionInput[AppPermission.SPEECH_RECOGNITION.name] = this.project.usesVoiceRecognition as boolean;
    this.permissionInput[AppPermission.PHOTOS.name] = this.project.usesPhotos as boolean;
    this.permissionInput[AppPermission.SINGLE_SIGN_ON.name] = this.project.usesSingleSignOn as boolean;
    this.permissionInput[AppPermission.SURVEYS.name] = this.project.usesSurveys as boolean;
  }

  private prepareProjectForUpload() {
    this.project.usesBluetooth = this.permissionInput[AppPermission.BLUETOOTH.name];
    this.project.usesBodyData = this.permissionInput[AppPermission.BODY_DATA.name];
    this.project.usesCamera = this.permissionInput[AppPermission.CAMERA.name];
    this.project.usesCalendar = this.permissionInput[AppPermission.CALENDAR.name];
    this.project.usesContacts = this.permissionInput[AppPermission.CONTACTS.name];
    this.project.usesFilesystem = this.permissionInput[AppPermission.FILESYSTEM.name];
    this.project.usesGps = this.permissionInput[AppPermission.LOCATION.name];
    this.project.usesMicrophone = this.permissionInput[AppPermission.MICROPHONE.name];
    this.project.usesNotes = this.permissionInput[AppPermission.NOTES.name];
    this.project.usesVoiceRecognition = this.permissionInput[AppPermission.SPEECH_RECOGNITION.name];
    this.project.usesPhotos = this.permissionInput[AppPermission.PHOTOS.name];
    this.project.usesSingleSignOn = this.permissionInput[AppPermission.SINGLE_SIGN_ON.name];
    this.project.usesSurveys = this.permissionInput[AppPermission.SURVEYS.name];

    this.project.uploads = [
      ...this.galleryImages,
    ];

    if (this.uploadedLogoData && this.uploadedLogoData.filename) {
      this.project.uploads.push(this.uploadedLogoData);
    }

    this.project.types = this.mapAppInputsToDto();
    this.project.isPublished = false;
    this.project.federalStates = (this.$refs.map as LeafletMap).selectedFederalStates;
    this.project.counties = (this.$refs.map as LeafletMap).selectedCounties;
    this.project.markers = (this.$refs.map as LeafletMap).selectedMarkers.filter((value, index, self) => index === self.findIndex((m) => (
      m.lat === value.lat && m.lng === value.lng && m.federalState === value.federalState && m.county === value.county
    )));
  }

  private async saveProject(): Promise<ProjectRestDto> {
    let result: AxiosResponse<ProjectRestDto>;

    if (this.editExistingProject) {
      result = await Services.projects.updateProjectById(this.project.uuid as string, this.project);
    } else {
      result = await Services.projects.createProject(this.project);
    }
    if (this.project.usesSingleSignOn) {
      this.projectUsesSingleSignOn = true;
      this.updateClientInformationInView(result.data);
    }
    if (this.project.usesSurveys) {
      this.projectUsesSurveys = true;
      this.updateSurveyKeyInformationInView(result.data);
    }

    return result.data;

  }

  private updateClientInformationInView(project: ProjectRestDto) {
    this.permissions.map((p) => {
      if (p === AppPermission.SINGLE_SIGN_ON) {
        p.setMessageBoxType(MessageBoxType.SUCCESS);
        p.setMessageBoxText([`<p>Client-ID: ${project.clientId} </p>`, `<p>Client-Secret: ${project.clientSecret}</p>`, 'WICHTIG: Bitte geben Sie Ihre Client-ID und das Client-Secret nicht an andere Personen weiter!']);
      }
      return p;
    });
  }

  private updateSurveyKeyInformationInView(project: ProjectRestDto) {
    this.permissions.map((p) => {
      if (p === AppPermission.SURVEYS) {
        p.setMessageBoxType(MessageBoxType.SUCCESS);
        p.setMessageBoxText([`<p>Survey-Key: ${project.surveyKey}</p>`, '<p>WICHTIG: Bitte geben Sie Ihren Survey-Key nicht an andere Personen weiter!</p>']);
      }
      return p;
    });
  }

  private async save() {
    try {
      this.loading = true;
      this.errors = [];
      this.prepareProjectForUpload();
      this.project = await this.saveProject();
      this.galleryImages = this.project.uploads?.filter((upload: ProjectUploadRestDto) => !upload.isAppLogo) as ProjectUploadRestDto[];
      this.editExistingProject = true;
      this.uploadSuccessful = true;
      this.loading = false;
      this.showSuccessMessage = true;
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 5000);
    } catch (err: unknown) {
      this.loading = false;
      this.uploadSuccessful = false;
      this.showSuccessMessage = true;
      this.hideSuccessMessageAfter5Seconds();
      const error = err as AxiosError;
      if (error.response) {
        this.errors = error.response.data.errors;
      }
    }
  }

  private async publish() {
    const publishStateBeforeSubmit: PublishTypeEnum = this.project.publishState as PublishTypeEnum;
    try {
      this.loading = true;
      this.errors = [];
      this.prepareProjectForUpload();

      this.project.publishState = this.$store.state.role === Role.ADMIN ? PublishTypeEnum.Published : PublishTypeEnum.InReview;
      this.project = await this.saveProject();
      this.galleryImages = this.project.uploads?.filter((upload: ProjectUploadRestDto) => !upload.isAppLogo) as ProjectUploadRestDto[];
      this.editExistingProject = true;
      this.uploadSuccessful = true;
      this.loading = false;
      this.showSuccessMessage = true;
      this.hideSuccessMessageAfter5Seconds();
      await Services.mails.sendProjectToReviewNotificationToAdmins({ surveyName: this.project.name });
    } catch (err: unknown) {
      this.project.publishState = publishStateBeforeSubmit;
      this.uploadSuccessful = false;
      this.loading = false;
      this.showSuccessMessage = true;
      this.hideSuccessMessageAfter5Seconds();
      const error = err as AxiosError;
      if (error.response) {
        this.errors = error.response.data.errors;
      }
    }
  }

  private hideSuccessMessageAfter5Seconds() {
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 5000);
  }

  private openConfirmReviewDialog() {

    if (this.$store.state.role === Role.ADMIN) {
      this.publish();
    } else {
      this.showConfirmReviewDialog = true;
    }
  }

  private confirmReviewDialog() {
    this.showConfirmReviewDialog = false;
    this.publish();
  }

  private cancelReviewDialog() {
    this.showConfirmReviewDialog = false;
  }

  private async acceptProject() {
    try {
      this.loading = true;
      await Services.projects.acceptProject(this.project.uuid as string);
      const userResponse = await Services.users.getUserByUserId(this.project.developerId as string);
      await Services.mails.sendProjectAcceptedMail({
        email: userResponse.data.emailAddress,
        message: `Ihr Projekt mit dem Titel ${this.project.name} wurde akzeptiert.`,
      });
      this.uploadSuccessful = true;
      this.showSuccessMessage = true;
      this.project.publishState = PublishTypeEnum.Published;
      this.hideSuccessMessageAfter5Seconds();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  private rejectProject() {
    this.showRejectionDialog = true;
  }

  private async confirmRejectionDialog() {
    if (!this.rejectionReasonText.trim()) {
      if (this.rejectionDialogErrorMessages.length <= 0) {
        this.rejectionDialogErrorMessages.push('Es muss ein Grund für die Ablehnung des Projekts angegeben werden.');
      }
      return;
    }

    try {
      this.rejectionDialogErrorMessages.length = 0;
      this.showRejectionDialog = false;
      this.loading = true;

      await Services.projects.rejectProject({
        projectUuid: this.project.uuid,
        reviewerUuid: this.$store.state.userId,
        text: this.rejectionReasonText,
      });

      const userResponse = await Services.users.getUserByUserId(this.project.developerId as string);
      await Services.mails.sendProjectRejectedMail({
        developerMail: userResponse.data.emailAddress,
        surveyName: this.project.name,
        rejectionReason: this.rejectionReasonText,
      });

      this.rejectionReasonText = '';
      this.uploadSuccessful = true;
      this.showSuccessMessage = true;
      this.project.publishState = PublishTypeEnum.Rejected;
      this.hideSuccessMessageAfter5Seconds();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;

  }

  private cancelRejectionDialog() {
    this.rejectionDialogErrorMessages.length = 0;
    this.showRejectionDialog = false;
    this.rejectionReasonText = '';
  }

  private get showSurveyPermissionSection(): boolean {
    return this.$store.state.isLoggedIn && (this.$store.state.role === Role.ADMIN || (this.$store.state.role === Role.DEVELOPER && this.projectUsesSurveys));
  }

  private get showSSOPermissionSection(): boolean {
    return this.$store.state.isLoggedIn && (this.$store.state.role === Role.ADMIN || (this.$store.state.role === Role.DEVELOPER && this.projectUsesSingleSignOn));
  }
}
