
















import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import MarkdownEditor from '@/components/partials/MarkdownEditor.vue';
import { NewsValidationError } from '@/views/news/NewsEditor.vue';
import BaseNewsBlock from '@/components/news/BaseNewsBlock.vue';
import Header from '@/assets/headers';
import { NewsBlock } from '@/apis/newsapi';

@Component({
  components: {
    MarkdownEditor,
    BaseNewsBlock,
  },
})
export default class TextBlock extends Vue {
  private Header = Header;

  @VModel({ required: true })
  private data!: NewsBlock;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private readonly deleteButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: ([]) })
  private errors!: NewsValidationError[];

  private get errorsToDisplay(): string[] {
    const matchingErrorCodes: string[] = this.errors.filter((e) => e.location === 'BLOCK' && e.blockPosition === this.data.position).map((e) => e.code);
    const errorMessages: string[] = [];

    matchingErrorCodes.forEach((code) => {
      if (code === 'TEXT_EMPTY') errorMessages.push('Der Text darf nicht leer sein');
    });

    return errorMessages;
  }

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }
}
