



















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import {
  BlockType, NewsBlock, NewsPage, NewsPageStatus,
} from '@/apis/newsapi';
import ButtonType from '@/assets/buttonTypes';
import Header from '@/assets/headers';
import Services from '@/assets/services/Services';
import DeleteDialog from '@/components/DeleteDialog.vue';
import AddBlockButton from '@/components/news/AddBlockButton.vue';
import NewsBlockButtonBar from '@/components/news/NewsBlockButtonBar.vue';
import NewsBlockSelectionDialog, {
  SelectableDialogEntry,
} from '@/components/news/NewsBlockSelectionDialog.vue';
import NewsBlockWrapper from '@/components/news/NewsBlockWrapper.vue';
import BackLinkWithArrow from '@/components/partials/BackLinkWithArrow.vue';
import DateTimeInput from '@/components/partials/DateTimeInput.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import SingleImageUpload, { UploadImageData } from '@/components/SingleImageUpload.vue';
import Page from '@/components/partials/Page.vue';

export interface NewsValidationError {
  code: string;
  location: string;
  blockPosition: number;
}

@Component({
  components: {
    NewsBlockSelectionDialog,
    AddBlockButton,
    RoundedButtonFilled,
    NewsBlockButtonBar,
    DeleteDialog,
    NewsBlockWrapper,
    SingleImageUpload,
    DateTimeInput,
    TextInputArea,
    TextHeader,
    BackLinkWithArrow,
    LoadingAnimation,
    Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class NewsEditor extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private BlockType = BlockType;
  private NewsStatus = NewsPageStatus;

  private initialLoading: boolean = true;
  private showUnexpectedErrorMessage: boolean = false;
  private showDeleteDialog: boolean = false;
  private idCounter: number = 0;

  private showBlockSelector: boolean = false;
  private positionToAddBlock: number | undefined = undefined;

  private showSuccessMessage: boolean = false;
  private uploadSuccessful: boolean = false;
  private loading: boolean = false;

  private editExistingProject: boolean = false;

  @Prop({ default: undefined })
  private readonly newsUUID!: string;

  private newsPage: NewsPage = {
    titleImage: {},
    status: NewsPageStatus.Editing,
    publishDate: new Date().toISOString(),
    blocks: [],
  }
  private logoSuccessFullyUploaded: boolean = false;
  private indexOfSectionToDelete: number | undefined = undefined;

  private newsBlockTypesToSelect: SelectableDialogEntry[] = [
    {
      type: BlockType.Text,
      text: 'Text hinzufügen',
      iconComponent: 'icon-add-text',
    },
    {
      type: BlockType.Image,
      text: 'Bild hinzufügen',
      iconComponent: 'icon-add-image',
    },
    {
      type: BlockType.ImageWithText,
      text: 'Text & Bild hinzufügen',
      iconComponent: 'icon-add-text-and-image',
    },
    {
      type: BlockType.Gallery,
      text: 'Galerie hinzufügen',
      iconComponent: 'icon-add-gallery',
    },
    {
      type: BlockType.Video,
      text: 'Video hinzufügen',
      iconComponent: 'icon-add-video',
    },
  ];

  private errors: NewsValidationError[] = [];

  private get errorsToDisplay() {
    return this.errors.filter((e) => e.location === 'GLOBAL').map((e) => e.code);
  }

  private async mounted() {

    if (this.newsUUID) {
      try {
        const response = await Services.news.getNewsPageById(this.newsUUID);
        this.newsPage = response.data;
        this.editExistingProject = true;
      } catch (e) {
        this.showUnexpectedErrorMessage = true;
      }
    }

    try {
      const response = await Services.users.getCurrentUser();
      this.newsPage.lastEditingUserId = response.data.userId;
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
    }

    this.initialLoading = false;
  }

  private async save() {
    await this.savePage(false);
  }

  private async publish() {
    await this.savePage(true);
  }

  private async savePage(publish: boolean) {

    this.errors = [];
    this.showSuccessMessage = false;
    this.uploadSuccessful = false;
    this.loading = true;

    if (publish) this.newsPage.status = NewsPageStatus.WaitingToPublish;

    const blocksBeforeSubmit: NewsBlock[] = this.newsPage.blocks as NewsBlock[];
    this.newsPage.blocks = this.getBlocksWithoutTemporaryIds();

    try {
      if (this.editExistingProject) {
        const result = await Services.news.updateNewsPage(this.newsPage.uuid as string, this.newsPage);
        this.newsPage = result.data;
      } else {
        const result = await Services.news.createNewsPage(this.newsPage);
        this.newsPage = result.data;
        this.editExistingProject = true;
      }
      this.uploadSuccessful = true;
      this.errors.length = 0;
    } catch (err: unknown) {
      const error = err as AxiosError;
      this.newsPage.blocks = blocksBeforeSubmit;
      if (error.response && error.response.data.errors && error.response.data.errors.length > 0) {
        error.response.data.errors.forEach((e: NewsValidationError) => this.errors.push(e));
      }
      this.uploadSuccessful = false;
    }

    this.showSuccessMessage = true;
    this.loading = false;
    this.hideSuccessMessageAfter5Seconds();
  }

  private getBlocksWithoutTemporaryIds(): NewsBlock[] {
    // eslint-disable-next-line no-param-reassign, no-unused-expressions
    return this.newsPage.blocks?.map(this.removeTemporaryId) as NewsBlock[];
  }

  private removeTemporaryId(block: NewsBlock): NewsBlock {
    return {
      ...block,
      uuid: block.uuid && block.uuid.length < 36 ? undefined : block.uuid,
    };
  }

  private hideSuccessMessageAfter5Seconds() {
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 5000);
  }

  private get sortedBlocks(): NewsBlock[] {
    return (this.newsPage.blocks as NewsBlock[]).sort((s1, s2) => ((s1.position as number) < (s2.position as number) ? -1 : 1));
  }

  private get publishDate(): Date {
    if (this.newsPage && this.newsPage.publishDate) {
      return new Date(Date.parse(this.newsPage.publishDate as string));
    }
    return new Date();
  }

  private set publishDate(date: Date) {
    date.setMilliseconds(0);
    date.setSeconds(0);
    this.newsPage.publishDate = date.toISOString();
  }

  private get titleImagePath(): UploadImageData {

    const imageData: UploadImageData = {
      path: '',
      filename: '',
    };

    if (this.newsPage.titleImage && this.newsPage.titleImage.uuid) {

      imageData.filename = `${this.newsPage.titleImage.filename}.${this.newsPage.titleImage.filetype}`;

      if (this.newsPage.titleImage.isTemporaryUpload) {
        imageData.path = `${process.env.VUE_APP_NEWS_SERVICE_IMAGE_URL}temporary/${this.newsPage.titleImage.uuid}`;
      } else {
        imageData.path = `${process.env.VUE_APP_NEWS_SERVICE_IMAGE_URL}${this.newsPage.titleImage.uuid}`;
      }
    }

    return imageData;
  }

  private async uploadTitleImage(file: File) {
    if (this.newsPage.titleImage && this.newsPage.titleImage.id !== undefined) {
      await this.deleteTitleImage();
    }
    const response = await Services.news.uploadImageTemporary(file);
    this.newsPage.titleImage = response.data;
    this.logoSuccessFullyUploaded = true;
  }

  private async deleteTitleImage() {
    this.newsPage.titleImage = {};
  }

  private moveSectionUp(index: number) {

    if (index > 0) {
      const sectionToMoveUp = (this.newsPage.blocks as NewsBlock[]).find((s) => s.position === index);
      const sectionToSwapWith = (this.newsPage.blocks as NewsBlock[]).find((s) => s.position === (index - 1));

      if (sectionToMoveUp && sectionToSwapWith) {
        sectionToMoveUp.position = index - 1;
        sectionToSwapWith.position = index;
      }
    }
  }

  private moveSectionDown(index: number) {

    if (index < (this.newsPage.blocks as NewsBlock[]).length - 1) {
      const sectionToMoveUp = (this.newsPage.blocks as NewsBlock[]).find((s) => s.position === index);
      const sectionToSwapWith = (this.newsPage.blocks as NewsBlock[]).find((s) => s.position === (index + 1));

      if (sectionToMoveUp && sectionToSwapWith) {
        sectionToMoveUp.position = index + 1;
        sectionToSwapWith.position = index;
      }
    }
  }

  private openSectionDeleteDialog(indexOfSectionToDelete: number) {
    this.indexOfSectionToDelete = indexOfSectionToDelete;
    this.showDeleteDialog = true;
  }

  private cancelSectionDeleteDialog() {
    this.showDeleteDialog = false;
    this.indexOfSectionToDelete = undefined;
  }

  private openBlockSelectionDialog(position: number) {
    this.positionToAddBlock = position;
    this.showBlockSelector = true;
  }

  private closeBlockSelectionDialog() {
    this.positionToAddBlock = undefined;
    this.showBlockSelector = false;
  }

  private addBlock(type: BlockType) {
    if (this.positionToAddBlock === undefined) return;
    this.addBelow(this.positionToAddBlock, type);
    this.closeBlockSelectionDialog();
  }

  private addAbove(position: number, type: BlockType) {

    const newSection: NewsBlock = this.createNewsBlock(position, type);

    const sectionsBefore = (this.newsPage.blocks as NewsBlock[]).filter((s) => (s.position as number) < position);
    const sectionsAfter = (this.newsPage.blocks as NewsBlock[]).filter((s) => (s.position as number) >= position);

    // eslint-disable-next-line no-param-reassign
    sectionsAfter.forEach((s) => (s.position as number) += 1);

    this.newsPage.blocks = [...sectionsBefore, newSection, ...sectionsAfter];
  }

  private addBelow(position: number, type: BlockType) {

    const newSection: NewsBlock = this.createNewsBlock(position + 1, type);

    const sectionsBefore = (this.newsPage.blocks as NewsBlock[]).filter((s) => s.position as number <= position);
    const sectionsAfter = (this.newsPage.blocks as NewsBlock[]).filter((s) => s.position as number > position);

    // eslint-disable-next-line no-param-reassign
    sectionsAfter.forEach((s) => (s.position as number) += 1);

    this.newsPage.blocks = [...sectionsBefore, newSection, ...sectionsAfter];
  }

  private createNewsBlock(position: number, type: BlockType): NewsBlock {

    const uuid: number = this.idCounter;
    this.idCounter += 1;

    return {
      uuid: `${uuid}`,
      position: position,
      type: type,
      images: type === BlockType.Image || type === BlockType.ImageWithText || BlockType.Gallery || type === BlockType.Video ? [] : undefined,
      markdownText: type === BlockType.Text || type === BlockType.Hero || type === BlockType.ImageWithText ? '' : undefined,
      videoLink: type === BlockType.Video ? '' : undefined,
      isImageOnLeftSide: type === BlockType.ImageWithText ? true : undefined,
    };
  }

  private deleteSection() {

    const sections: NewsBlock[] = (this.newsPage.blocks as NewsBlock[]).filter((s) => s.position !== this.indexOfSectionToDelete);
    sections.forEach((s) => {
      if (s.position as number > (this.indexOfSectionToDelete as number)) {
        // eslint-disable-next-line no-param-reassign
        (s.position as number) -= 1;
      }
    });

    this.indexOfSectionToDelete = undefined;
    this.newsPage.blocks = sections;
    this.showDeleteDialog = false;
  }
}
