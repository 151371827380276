















































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { NewsValidationError } from '@/views/news/NewsEditor.vue';
import HeroBlock from '@/components/news/HeroBlock.vue';
import VideoBlock from '@/components/news/VideoBlock.vue';
import GalleryBlock from '@/components/news/GalleryBlock.vue';
import ImageBlock from '@/components/news/ImageBlock.vue';
import TextWithImageBlock from '@/components/news/TextWithImageBlock.vue';
import TextBlock from '@/components/news/TextBlock.vue';
import { BlockType, NewsBlock } from '@/apis/newsapi';

@Component({
  components: {
    HeroBlock,
    VideoBlock,
    GalleryBlock,
    ImageBlock,
    TextWithImageBlock,
    TextBlock,
  },
})
export default class NewsBlockWrapper extends Vue {
  BlockType = BlockType;

  @Prop({ required: true })
  private type!: BlockType;

  @VModel({ required: true })
  private data!: NewsBlock

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private readonly deleteButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: ([]) })
  private errors!: NewsValidationError[];

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }
}
