















































import {
  Component, Emit, Vue,
} from 'vue-property-decorator';
import { BlockType } from '@/apis/newsapi';
import ButtonType from '@/assets/buttonTypes';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';

@Component({
  components: { RoundedButtonOutlined },
})
export default class NewsBlockButtonBar extends Vue {
  private ButtonType = ButtonType;
  private BlockType = BlockType;

  @Emit()
  private click(type: BlockType) {
    // emits event
  }
}
