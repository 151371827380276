























import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import { BlockType } from '@/apis/newsapi';
import ButtonType from '@/assets/buttonTypes';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';

@Component({
  components: {
    IconInCircle, TextHeader,
  },
})
export default class BaseNewsBlock extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private BlockType = BlockType;

  @Prop({ required: true })
  private position!: number;

  @Prop({ required: true })
  private title!: string;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private readonly deleteButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: ([]) })
  private errorMessages!: string[];

  private get heading(): string {
    return `#${this.position + 1} - ${this.title}`;
  }

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }
}
