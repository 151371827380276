

















































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { AndroidVersion, IOSVersion } from '@/apis/projectapi';
import Header from '@/assets/headers';
import Services from '@/assets/services/Services';
import AppVersionType, { AppInput } from '@/assets/types/appversiontypes';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';

@Component({
  components: { TextInput, TextHeader },
})
export default class AppVersionInput extends Vue {
  private Header = Header;
  private AppVersionType = AppVersionType;

  @Prop({ required: true })
  private readonly type!: AppVersionType;

  @Prop({ default: [] })
  private readonly errors!: string[];

  @VModel({ required: true })
  private model!: AppInput;

  private selectableOsVersions: Array<IOSVersion | AndroidVersion> = [];

  private languageInput = '';

  @Emit()
  private deleteButtonClicked() {
    // empty
  }

  private get header(): string {
    if (this.type === AppVersionType.IOS) {
      return 'iOS App';
    }

    if (this.type === AppVersionType.ANDROID) {
      return 'Android App';
    }

    return 'Web-Applikation';
  }

  private get versionHeader(): string {
    if (this.type === AppVersionType.IOS) {
      return 'Minimum iOS Version';
    }

    if (this.type === AppVersionType.ANDROID) {
      return 'Minimum Android Version';
    }

    return '';
  }

  private async mounted() {
    if (this.type === AppVersionType.ANDROID) {
      this.selectableOsVersions = (await Services.projects.getAndroidVersions()).data;
    } else if (this.type === AppVersionType.IOS) {
      this.selectableOsVersions = (await Services.projects.getIOSVersions()).data;
    }
  }

  private addLanguage() {
    this.languageInput = this.languageInput.trim();
    if (this.languageInput) {
      this.model.languages.push(this.languageInput);
    }
    this.languageInput = '';
  }

  private get languageInputErrorMessages(): string[] {
    if (this.errors.includes('ANDROID_APP_LANGUAGES_UNDEFINED')
        || this.errors.includes('IOS_APP_LANGUAGES_UNDEFINED')
        || this.errors.includes('WEB_APP_LANGUAGES_UNDEFINED')) {

      return ['Es muss mindestens eine Sprache angegeben werden.'];
    }
    return [];
  }

  private removeLanguage(languageToRemove: string) {
    this.model.languages = this.model.languages.filter((lang) => lang !== languageToRemove);
  }
}
