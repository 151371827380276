




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import MarkdownEditor from '@/components/partials/MarkdownEditor.vue';
import NewsCard from '@/components/news/NewsCard.vue';
import ProjectCard from '@/components/projects/ProjectCard.vue';
import { ProjectOverviewRestDto } from '@/apis/projectapi';
import TextHeader from '@/components/partials/TextHeader.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import {
  BlockType, FileUpload, NewsPage, NewsPageOverview,
} from '@/apis/newsapi';
import Header from '@/assets/headers';
import Services from '@/assets/services/Services';
import ImageGallery from '@/components/ImageGallery.vue';
import DateService from '@/assets/services/DateService';
import Page from '@/components/partials/Page.vue';

@Component({
  components: {
    MarkdownEditor,
    ProjectCard,
    NewsCard,
    TextHeader,
    LoadingAnimation,
    ImageGallery,
    Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class NewsArticle extends Vue {
  private Header = Header;
  private BlockType = BlockType;

  private loading: boolean = true;
  private showUnexpectedErrorMessage: boolean = false;

  @Prop({ required: true })
  private slug!: string;

  private newsPage: NewsPage = {};
  private galleryImagePaths: Record<string, string[]> = {};

  private dateTimeFormatter = DateService.dateTimeFormatterWithoutTime;

  private publishDate: string = '';

  private otherNews: NewsPageOverview[] = [];
  private projects: ProjectOverviewRestDto[] = [];

  private async mounted() {
    if (this.slug) {
      try {
        this.newsPage = (await Services.news.getNewsPageBySlug(this.slug)).data;
        this.publishDate = this.dateTimeFormatter.format(new Date(Date.parse(this.newsPage.publishDate as string)));

        // eslint-disable-next-line no-unused-expressions
        this.newsPage.blocks?.forEach((b) => {
          if (b.type === BlockType.Gallery) {
            const uuid: string = b.uuid as string;
            this.galleryImagePaths[uuid] = b.images?.map((i) => this.getImagePath(i)) as string[];
          }
        });

        this.newsPage.blocks = this.newsPage.blocks?.sort((b1, b2) => ((b1.position as number) < (b2.position as number) ? -1 : 1));
      } catch (e) {
        this.showUnexpectedErrorMessage = true;
      }
    }

    try {
      const response = await Services.news.getAllPublishedNews(0, 3, '', 'date', 'desc');
      // eslint-disable-next-line no-unused-expressions
      response.data.newsPages?.forEach((n) => {
        if (this.otherNews.length < 2 && n.uuid !== this.newsPage.uuid) {
          this.otherNews.push(n);
        }
      });
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
    }

    try {
      const response = await Services.projects.getPublishedProjects(0, 2, '', undefined, 'date', 'desc');
      this.projects = response.data.projects as ProjectOverviewRestDto[];
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
    }

    this.loading = false;
  }

  private getImagePath(upload: FileUpload) {
    if (!upload || !upload.uuid) return '';
    return `${process.env.VUE_APP_NEWS_SERVICE_IMAGE_URL}${upload.uuid}`;
  }

  private getProjectImagePath(imageId: number) {
    if (!imageId) return '';
    return `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}${imageId}`;
  }

  private formatAndGetNewsPublishDate(dateString: string): string {
    if (dateString) {
      return this.dateTimeFormatter.format(new Date(Date.parse(dateString)));
    }
    return this.dateTimeFormatter.format(new Date());
  }
}
