
















import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { directive as onClickAway } from 'vue-clickaway';
import MarkdownEditor from '@/components/partials/MarkdownEditor.vue';
import { NewsValidationError } from '@/views/news/NewsEditor.vue';
import BaseNewsBlock from '@/components/news/BaseNewsBlock.vue';
import Header from '@/assets/headers';
import { NewsBlock } from '@/apis/newsapi';

@Component({
  components: {
    MarkdownEditor,
    BaseNewsBlock,
  },
  directives: {
    onClickAway,
  },
})
export default class HeroBlock extends Vue {
  private Header = Header;

  private showBackgroundColorPicker: boolean = false;
  private showTextColorPicker: boolean = false;

  @VModel({ required: true })
  private data!: NewsBlock;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private readonly deleteButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: ([]) })
  private errors!: NewsValidationError[];

  private get errorsToDisplay(): string[] {
    const matchingErrorCodes: string[] = this.errors.filter((e) => e.location === 'BLOCK' && e.blockPosition === this.data.position).map((e) => e.code);
    const errorMessages: string[] = [];

    matchingErrorCodes.forEach((code) => {
      if (code === 'TEXT_EMPTY') errorMessages.push('Der Text darf nicht leer sein');
    });

    return errorMessages;
  }

  private get backgroundColor() {
    return this.data.backgroundColor ? { hex: this.data.backgroundColor } : { hex: '' };
  }

  private set backgroundColor(value: { hex: string }) {
    this.data.backgroundColor = value.hex;
  }

  private get textColor() {
    return this.data.textColor ? { hex: this.data.textColor } : { hex: '' };
  }

  private set textColor(value: { hex: string }) {
    this.data.textColor = value.hex;
  }

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }

  private get backgroundColorCircleCss() {
    return `background-color: ${this.data.backgroundColor ? this.data.backgroundColor : ''}`;
  }

  private get textColorCircleCss() {
    return `background-color: ${this.data.textColor ? this.data.textColor : ''}`;
  }

  private toggleBackgroundColorPicker() {
    this.showBackgroundColorPicker = !this.showBackgroundColorPicker;
    this.showTextColorPicker = false;
  }

  private toggleTextColorPicker() {
    this.showTextColorPicker = !this.showTextColorPicker;
    this.showBackgroundColorPicker = false;
  }

  private closeBackgroundColorPicker(event: PointerEvent) {
    if (event.target && (event.target as HTMLElement).id !== 'backgroundColorCircle') {
      this.showBackgroundColorPicker = false;
    }
  }

  private closeTextColorPicker(event: PointerEvent) {
    if (event.target && (event.target as HTMLElement).id !== 'textColorCircle') {
      this.showTextColorPicker = false;
    }
  }
}
