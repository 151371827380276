// eslint-disable-next-line no-shadow
enum AppVersionType {
  ANDROID,
  IOS,
  WEB
}

export type AppInput = { id: number; languages: string[]; size: string; osVersionId: number; weblink: string }

export default AppVersionType;
